import React from 'react';
import { graphql, Link } from 'gatsby';
import Container from '../../components/Container';
import formatDate from '../../utils/formatDate';
import Layout from '../../components/Layout';
import Stack from '../../components/Stack';
import styles from './wiki.module.scss';

export default function WikiPage({
  data: { contentfulFetchWikiPages: data },
  pageContext,
}) {
  return (
    <Layout title='Wiki' className={styles.layout}>
      <Container className={styles.container}>
        <Stack direction='column'>
          <div>
            <h1 className={styles.title}>{data.title}</h1>
            <p>
              Last updated @{' '}
              <span className={styles.date}>
                {formatDate(new Date(pageContext.last_updated))}
              </span>
            </p>
          </div>
          <div
            className={styles.blurb}
            dangerouslySetInnerHTML={{
              __html: data.content.childMarkdownRemark.html,
            }}
          />
        </Stack>
        <ul className={styles.pages}>
          {data.wikipages.map(({ title, short, slug }) => (
            <li key={slug}>
              <Stack
                spacing='tad'
                as={Link}
                className={styles.link}
                to={`/wiki/${slug}`}
              >
                <h2 className={styles.subtitle}>{title}</h2>
                <p className={styles.text}>{short}</p>
              </Stack>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    contentfulFetchWikiPages(contentful_id: { eq: $id }) {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      wikipages {
        title
        short
        slug
      }
    }
  }
`;
